import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b34dfd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-content"
}
const _hoisted_2 = {
  key: 1,
  class: "card-content"
}
const _hoisted_3 = {
  key: 2,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.border ? 'card--border' : ''])
  }, [
    _createVNode(_component_HeadingComponent, {
      "sub-heading": true,
      "sub-heading-level": _ctx.cardHeadingLevel,
      divider: false,
      title: _ctx.cardTitle
    }, {
      right: _withCtx(() => [
        _renderSlot(_ctx.$slots, "headingActions", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["sub-heading-level", "title"]),
    (_ctx.cardContentWasPassed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "cardContent", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.renderDefaultSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footerActions", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}